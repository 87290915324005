import React from "react";
import "./Pages.scss";

import Logo from "../../images/atv-epaper-logo.png";
import SearchButton from "../../images/icons/SearchButton.png";
import NewsCard from "../cards/NewsCard";
import { getEPaperList, getLegacyEPaper } from "../../utils/Requests";
import { ScaleLoader } from "react-spinners";
import APIResult from "../../configs/APIResult";
import LatestNewsCard from "../cards/LatestNewsCard";
import Footer from "../Footer";
import { gaBrowserTracking } from "../../utils/Utilities";

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      epaper: null,
      epapers: [],
      favourites: [],
      legacyEpapers: [],
      total: 0,
      currentPage: 0,
    };

    this.gettingEPapers = false;
    this.gettingLegacyEPapers = true;

    this.getLatestEPaper = this.getLatestEPaper.bind(this);

    this.getLegacyEpaper = this.getLegacyEpaper.bind(this);

    this.enterEPaperPage = this.enterEPaperPage.bind(this);

    this.onScrollEPapers = this.onScrollEPapers.bind(this);

    this.getLatestCard = this.getLatestCard.bind(this);
  }

  async onScrollEPapers(e) {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight - 10;
    if (bottom) {
      if (
        !this.gettingEPapers &&
        this.state.total > this.state.epapers.length
      ) {
        this.gettingEPapers = true;
        let result = await this.getLatestEPaper(this.state.currentPage + 1);
        if (result) {
          this.setState({
            currentPage: this.state.currentPage + 1,
          });
        }
        this.gettingEPapers = false;
      }
    }
  }

  async getLegacyEpaper() {
    let result = await getLegacyEPaper();
    if ((result.status = APIResult.SUCCESS)) {
      this.setState({ legacyEpapers: result });
    }
  }

  componentDidMount() {
    this.getLegacyEpaper();
    gaBrowserTracking();
    this.getLatestEPaper();
    const favouritesFromLocalStorage = localStorage.getItem("favourites");
    if (favouritesFromLocalStorage && favouritesFromLocalStorage.length) {
      this.setState({
        favourites: JSON.parse(favouritesFromLocalStorage),
      });
    }
  }

  async getLatestEPaper(page = 0) {
    let result = await getEPaperList(0, 0, page, 10, "", "");
    if (result.status === APIResult.SUCCESS) {
      if (result.data.length > 0) {
        result.data = result.data.map((v) =>
          Object.assign(v, { seeMore: false })
        );
        var currentEPapers = this.state.epapers;
        result.data.forEach((eachResult) => {
          currentEPapers.push(eachResult);
        });
        this.setState({
          epaper: this.state.epaper ? this.state.epaper : result.data[0],
          epapers: currentEPapers,
          total: result.total,
        });
        return true;
      }
    }
    return false;
  }

  enterDiscoverPage(searchTag = "") {
    window.location.href =
      `/discover` + (searchTag === "" ? "" : "?t=" + searchTag);
  }

  enterEPaperPage(id, page) {
    window.location.href = `/epaper?id=${id}`;
  }

  enterLegacyEPaperSite(link) {
    window.location.href = link;
  }

  addToFavourites(epaper) {
    this.setState(
      {
        favourites: [...this.state.favourites, epaper],
      },
      () => {
        localStorage.setItem(
          "favourites",
          JSON.stringify(this.state.favourites)
        );
      }
    );
  }

  removeFromFavourites(id) {
    const array = this.state.favourites;

    var index = array
      .map(function (e) {
        return e.epaper_id;
      })
      .indexOf(id);
    if (index > -1) {
      array.splice(index, 1);
    }

    this.setState(
      {
        favourites: array,
      },
      () => {
        localStorage.setItem(
          "favourites",
          JSON.stringify(this.state.favourites)
        );
      }
    );
  }

  toggleEpapersSeeMore(index) {
    const epapers = this.state.epapers;
    epapers[index].seeMore = !epapers[index].seeMore;
    this.setState({ epapers: epapers });
  }

  getLatestCard() {
    if (this.state.epaper !== null)
      return <LatestNewsCard paper={this.state.epaper} />;
    if (this.state.legacyEpapers.length > 0) {
      let legacyEPaper = this.state.legacyEpapers[0];
      return (
        <LatestNewsCard
          url={"https://epaper.atvnewsonline.com/" + legacyEPaper.url}
          paper={{
            epaper_id: 0,
            publish_date_string: "",
            epaper_thumbnail:
              "https://epaper.atvnewsonline.com/" + legacyEPaper.cover,
            title: legacyEPaper.name,
            hashtags: [],
          }}
          type={1}
        />
      );
    }
    return (
      <div
        className="latest-news-parent center loader-container"
        style={{
          height: "unset",
          width: "100%",
          marginTop: "1em",
          boxSizing: "border-box",
        }}>
        <ScaleLoader color="red" />
      </div>
    );
  }

  render() {
    return (
      <div className="center white-bg footer-margin">
        <div className="home-parent">
          <img
            className="logo button"
            alt="logo"
            src={Logo}
            onClick={() => {
              const newWindow = window.open(
                "https://atvnewsonline.com/",
                "_blank",
                "noopener,noreferrer"
              );
              if (newWindow) {
                newWindow.opener = null;
              }
            }}
          />
          <div className="space-between title-height">
            <div className="title">主页</div>
            <img
              alt=""
              className="search-icon"
              src={SearchButton}
              onClick={() => this.enterDiscoverPage("")}
            />
          </div>
          <div className="divider-v divider-percent" />
          <div className="home-split">
            <div className="latest-news">
              <div className="sub-title">最新电子报</div>
              {this.getLatestCard()}
            </div>
            <div className="more-news">
              <div className="sub-title">更多</div>
              {this.state.epapers.length > 0 ||
              this.state.legacyEpapers.length > 0 ? (
                <div>
                  <div
                    onScroll={this.onScrollEPapers}
                    className="more-news-scroll"
                    style={{
                      marginTop: "1.5em",
                    }}>
                    {this.state.epapers.map((e, index) => {
                      return (
                        <NewsCard
                          paper={e}
                          key={"e-paper-card-" + e.publish_date_string}
                        />
                      );
                    })}

                    <div
                      className="sub-title"
                      style={{
                        paddingBottom: "1em",
                      }}>
                      经典版电子报
                    </div>
                    {this.state.legacyEpapers.map((legacyEPaper) => {
                      return (
                        <NewsCard
                          type={1}
                          url={
                            "https://epaper.atvnewsonline.com/" +
                            legacyEPaper.url
                          }
                          paper={{
                            epaper_id: 0,
                            publish_date_string: "",
                            epaper_thumbnail:
                              "https://epaper.atvnewsonline.com/" +
                              legacyEPaper.cover,
                            title: legacyEPaper.name,
                            hashtags: [],
                          }}
                          key={"e-paper-card-" + legacyEPaper.name}
                        />
                      );
                    })}
                    {this.state.total > this.state.epapers.length ? (
                      <div className="center-h">
                        <div className="loading-text">自动加载中</div>
                        <ScaleLoader color="red" />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className="latest-news-parent center loader-container"
                  style={{
                    width: "100%",
                    height: "unset",
                    marginTop: "1em",
                    boxSizing: "border-box",
                  }}>
                  <ScaleLoader color="red" />
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default HomePage;
