import React from "react";
import MoreVerticalIcon from "../../images/icons/MoreIconBlack.svg";
import CloseItemIcon from "../../images/icons/CloseItemIcon.svg";
import FavouriteIcon from "../../images/icons/HeartFullIcon.svg";
import UnfavouriteItemIcon from "../../images/icons/HeartEmptyIcon.svg";
import ShareIcon from "../../images/icons/ShareIcon.svg";
import { RWebShare } from "react-web-share";
import PropTypes from "prop-types";
import { usePageTracking } from "../../utils/Utilities.js";

class NewsCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMore: false,
      isFavourite: false,
      lastPageRead: 0,
      totalPage: 0,
    };

    this.getEPaperURL = this.getEPaperURL.bind(this);
  }

  componentDidMount() {
    const favouritesFromLocalStorage = localStorage.getItem("favourites");
    if (favouritesFromLocalStorage && favouritesFromLocalStorage.length) {
      const array = JSON.parse(favouritesFromLocalStorage);
      const index = array
        .map(function (e) {
          return e.epaper_id;
        })
        .indexOf(this.props.paper.epaper_id);
      if (index > -1) {
        this.setState({ isFavourite: true });
      } else {
        this.setState({ isFavourite: false });
      }
    }

    this.getPageRead();
  }

  addToFavourites() {
    const favouritesFromLocalStorage = localStorage.getItem("favourites");
    if (favouritesFromLocalStorage && favouritesFromLocalStorage.length) {
      const array = JSON.parse(favouritesFromLocalStorage);
      this.setState(
        {
          isFavourite: true,
        },
        () => {
          localStorage.setItem(
            "favourites",
            JSON.stringify([...array, this.props.paper])
          );
        }
      );
    } else {
      this.setState(
        {
          isFavourite: true,
        },
        () => {
          localStorage.setItem(
            "favourites",
            JSON.stringify([this.props.paper])
          );
        }
      );
    }

    this.setState({ showMore: false });
  }

  enterDiscoverPage(searchTag = "") {
    window.location.href =
      `/discover` + (searchTag === "" ? "" : "?t=" + searchTag);
  }

  getEPaperURL() {
    var url = "";
    if (this.props.type === 0) {
      url = `/epaper?id=${this.props.paper.epaper_id}`;
    }
    if (this.props.type === 1) {
      url = this.props.url;
    }

    return url;
  }

  enterEPaperPage() {
    window.location.href = this.getEPaperURL();
  }

  removeFromFavourites() {
    const favouritesFromLocalStorage = localStorage.getItem("favourites");
    if (favouritesFromLocalStorage && favouritesFromLocalStorage.length) {
      const array = JSON.parse(favouritesFromLocalStorage);
      const index = array
        .map(function (e) {
          return e.epaper_id;
        })
        .indexOf(this.props.paper.epaper_id);

      // Remove id from array
      if (index > -1) {
        array.splice(index, 1);
      }

      this.setState(
        {
          isFavourite: false,
        },
        () => {
          localStorage.setItem("favourites", JSON.stringify(array));
        }
      );
    }
    this.setState({ showMore: false });
  }

  getPageRead() {
    const ongoingPapersFromLocalStorage = localStorage.getItem("ongoingPapers");

    if (ongoingPapersFromLocalStorage && ongoingPapersFromLocalStorage.length) {
      var array = JSON.parse(ongoingPapersFromLocalStorage);
      const index = array
        .map(function (e) {
          return e.epaper_id;
        })
        .indexOf(this.props.paper.epaper_id.toString());
      if (index > -1) {
        this.setState({
          lastPageRead: array[index].page,
          totalPage: array[index].outOf,
        });
      }
    }
  }

  render() {
    return (
      <div
        className="e-paper-card-parent"
        key={"e-paper-card-" + this.props.paper.publish_date_string}>
        <img
          loading="lazy"
          className="e-paper-card-thumbnail"
          src={
            this.props.type === 0
              ? this.props.paper.epaper_path +
                this.props.paper.epaper_img_thumbnail[0]
              : this.props.paper.epaper_thumbnail
          }
          alt="thumbnail"
        />
        <div className="e-paper-card-info-parent">
          <div>
            <div className="e-paper-card-info-top">
              <div className="e-paper-card-title">{this.props.paper.title}</div>
              <div className="see-more-dropdown">
                <img
                  src={MoreVerticalIcon}
                  alt="more"
                  className="see-more-icon"
                  onClick={() => {
                    this.setState({
                      showMore: !this.state.showMore,
                    });
                  }}></img>
              </div>
            </div>
            <div className="e-paper-card-date">
              {this.props.paper.publish_date_string}
            </div>
          </div>
          <div className="e-paper-info-bottom-row">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}>
              <div className="e-paper-info-hashtags">
                {this.props.paper.hashtags.map((h) => {
                  return (
                    <div
                      key={`hashtag-${this.props.paper.publish_date_string}-${h.hashtag_name}`}
                      className="e-paper-info-hashtag"
                      onClick={() => {
                        this.enterDiscoverPage(h.hashtag_name);
                      }}>
                      {h.hashtag_name}
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className="wrapper"
              style={{
                width: "100%",
              }}>
              {this.state.lastPageRead > 0 ? (
                <div className="e-paper-card-ongoing-page">
                  {" "}
                  已阅读至 {this.state.lastPageRead}/ {this.state.totalPage}页{" "}
                </div>
              ) : null}
              <div
                className="red-button no-margin start-reading"
                onClick={() => {
                  this.enterEPaperPage();
                  usePageTracking(
                    "navigate",
                    "news",
                    this.props.paper.title
                  );
                }}>
                开始阅读
              </div>
            </div>
          </div>
        </div>
        {this.state.showMore ? (
          <div className="more-parent">
            {this.props.type === 0 ? (
              <div>
                {this.state.isFavourite ? (
                  <div
                    className="more-items-parent add-to-favourite"
                    onClick={() => {
                      this.removeFromFavourites();
                    }}>
                    <img
                      className="more-items-icon"
                      src={FavouriteIcon}
                      alt="unfavourite"
                    />
                    <div className="more-items-text">取消收藏</div>
                  </div>
                ) : (
                  <div
                    className="more-items-parent add-to-favourite"
                    onClick={() => {
                      this.addToFavourites();
                    }}>
                    <img
                      className="more-items-icon"
                      src={UnfavouriteItemIcon}
                      alt="favourite"
                    />
                    <div className="more-items-text">收藏</div>
                  </div>
                )}
              </div>
            ) : null}
            <RWebShare
              data={{
                text: `电子报 - ${this.props.paper.title}`,
                url: this.getEPaperURL(),
                title: `电子报 - ${this.props.paper.title}`,
              }}
              onClick={() => console.log("已成功分享")}>
              <div className="more-items-parent">
                <img
                  className="more-items-icon"
                  src={ShareIcon}
                  alt="favourite"
                />
                <div className="more-items-text">分享</div>
              </div>
            </RWebShare>
            <div
              className="more-items-parent"
              onClick={() => {
                this.setState({ showMore: false });
              }}>
              <img
                className="more-items-icon half-opacity"
                src={CloseItemIcon}
                alt="favourite"
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

NewsCard.propTypes = {
  paper: PropTypes.object,
  type: PropTypes.number,
  url: PropTypes.string,
};

NewsCard.defaultProps = {
  paper: {
    epaper_id: 0,
    publish_date_string: "-",
    epaper_thumbnail: "",
    title: "-",
    hashtags: [],
  },
  type: 0,
  url: "",
};

export default NewsCard;
