import React from "react";
import $ from "jquery";
import "./custom-turn.js";

class Turn extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            style: {},
            className: "",
            options: {},

        };
    }

    componentDidMount() {
        if (this.el) {
            $(this.el).turn(Object.assign({}, this.props.options));
            this
                .props
                .passBack(this.el);
        }
        document.addEventListener("keydown", this.handleKeyDown, false);
    }

    componentWillUnmount() {
        if (this.el) {
            $(this.el)
                .turn("destroy")
                .remove();
        }
        document.removeEventListener("keydown", this.handleKeyDown, false);
    }

    handleKeyDown = event => {
        if (event.keyCode === 37) {
            this.previousPage();
        }
        if (event.keyCode === 39) {
            this.nextPage();
        }
    };

    nextPage() {
        if(!this.props.blockFlip)
        {$(this.el).turn("next");}
    }
    previousPage() {
        if(!this.props.blockFlip)
        {$(this.el).turn("previous");}
    }

    render() {
        return (
            <div
                className={this.props.className}
                style={Object.assign({}, this.props.style)}
                ref={el => (this.el = el)}>
                {this.props.children}
            </div>
        );
    }
}

export default Turn;