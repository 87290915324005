import React from "react";
import "./Pages.scss";

class TestPage extends React.Component {
  constructor(props) {
    super(props);

  }
  
  render() {
    return (
      <div className="center white-bg footer-margin">
        <video
            style={{
            width: "-webkit-fill-available",
            aspectRatio: "16/9",
            margin: "2em",
            borderRadius: "0.25em",
            maxWidth: "720px",
            }}
            controls>
            <source
            src={"https://atvoss.oss-cn-hongkong.aliyuncs.com/wp-content/uploads/2022/07/20220705172651409.mp4" + "#t=0.001"}
            type="video/mp4"
            />
        </video>
        <video
            style={{
            width: "-webkit-fill-available",
            aspectRatio: "16/9",
            margin: "2em",
            borderRadius: "0.25em",
            maxWidth: "720px",
            }}
            controls>
            <source
            src={"https://atvoss.oss-cn-hongkong.aliyuncs.com/wp-content/uploads/2022/07/20220705150009245.mp4" + "#t=0.001"}
            type="video/mp4"
            />
        </video>
        <video
            style={{
            width: "-webkit-fill-available",
            aspectRatio: "16/9",
            margin: "2em",
            borderRadius: "0.25em",
            maxWidth: "720px",
            }}
            controls>
            <source
            src={"https://atvoss.oss-cn-hongkong.aliyuncs.com/wp-content/uploads/2022/07/20220704191050853.mp4" + "#t=0.001"}
            type="video/mp4"
            />
        </video>
      </div>
    );
  }
}

export default TestPage;
