import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faYoutube, faTelegram, faTwitter} from "@fortawesome/free-brands-svg-icons"
import React from "react";

class Footer extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {};
    }

    render()
    {
        return (
            <div className="footer">
                <div>
                    <div>关注我们</div>
                    <div className="divider-v"/>
                </div>
                <div className="footer-icons">
                    <a
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/asiatvnewssea"
                        target="_blank"
                        className="footer-icon"
                        style={{
                        backgroundColor: "#4267B2"
                    }}>
                        <FontAwesomeIcon icon={faFacebook}/>
                    </a>
                    <a
                        rel="noopener noreferrer"
                        href="https://www.twitter.com/asiatvnewssea"
                        target="_blank"
                        className="footer-icon"
                        style={{
                        backgroundColor: "#00acee"
                    }}>
                        <FontAwesomeIcon icon={faTwitter}/>
                    </a>
                    <a
                        rel="noopener noreferrer"
                        href="https://www.youtube.com/asiatvnewssea"
                        target="_blank"
                        className="footer-icon"
                        style={{
                        backgroundColor: "#c4302b"
                    }}>
                        <FontAwesomeIcon icon={faYoutube}/>
                    </a>
                    <a
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/asiatvnewssea"
                        target="_blank"
                        className="footer-icon"
                        style={{
                        backgroundColor: "#cd486b"
                    }}>
                        <FontAwesomeIcon icon={faInstagram}/>
                    </a>
                    <a
                        rel="noopener noreferrer"
                        href="https://t.me/asiatvnewssea"
                        target="_blank"
                        className="footer-icon"
                        style={{
                        backgroundColor: "#229ED9"
                    }}>
                        <FontAwesomeIcon icon={faTelegram}/>
                    </a>
                </div>
            </div>
        );
    }
}

export default Footer;