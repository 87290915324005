import React from "react";
import NewsCard from "../cards/NewsCard";

class FavouritesTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            favouritePapers: [],
        };
    }

    componentDidMount() {
        const favouritesFromLocalStorage = localStorage.getItem("favourites");
        if (favouritesFromLocalStorage && favouritesFromLocalStorage.length) {
            const array = JSON.parse(favouritesFromLocalStorage);

            this.setState({ favouritePapers: array });
        }
    }

    render() {
        return (
            <div className="w-100">
                {this.state.favouritePapers.length > 0 ? (
                    <div className="favourites-result">
                        {this.state.favouritePapers.map((e, index) => {
                            return (
                                <NewsCard
                                    paper={e}
                                    key={
                                        "e-paper-card-" + e.publish_date_string
                                    }
                                />
                            );
                        })}
                    </div>
                ) : (
                    <div className="text-info text-center">暂时没有任何已收藏电子报</div>
                )}
            </div>
        );
    }
}

export default FavouritesTab;
