import "./App.css";
import { Switch, Route } from "react-router";
import EPaperPage from "./components/pages/EPaperPage";
import { BrowserRouter } from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import DiscoverPage from "./components/pages/DiscoverPage";
import packageJson from "../package.json";
import ReactGA from "react-ga4";
import TestPage from "./components/pages/TestPage";
import React from "react";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCookiesDialog: false,
      maxHeight: 0,
    };

    this.checkCookiesAccept = this.checkCookiesAccept.bind(this);

    this.setCookiesAccept = this.setCookiesAccept.bind(this);
  }

  componentDidMount() {
    console.log(`AsiaTV EPaper - v${packageJson.version}`);
    const TrackingID = "G-VWS8PTK4SN";
    ReactGA.initialize(TrackingID);
    this.usePageTracking();

    this.checkCookiesAccept();

    this.setState({ maxHeight: window.innerHeight });
    window.addEventListener("resize", () => {
      this.setState({ maxHeight: window.innerHeight });
    });
  }

  checkCookiesAccept() {
    var agree = localStorage.getItem("pp-agree");
    if (agree === null || agree === undefined) {
      this.setState({ showCookiesDialog: true });
    }
  }

  setCookiesAccept() {
    localStorage.setItem("pp-agree", 1);
    this.setState({ showCookiesDialog: false });
  }

  usePageTracking() {
    ReactGA.send(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div style={{ height: this.state.maxHeight }}>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/discover" exact component={DiscoverPage} />
            <Route path="/epaper" exact component={EPaperPage} />
            <Route path="/test" exact component={TestPage} />
          </Switch>
        </BrowserRouter>
        {this.state.showCookiesDialog ? (
          <div className="cookies-dialog">
            <div>
              我们使用了Cookies 及 LocalStorage
              来确保您在网站上能获得良好的体验。
              如果您继续使用本网站，我们将认定您接受并理解我们的隐私政策和条款。
              <a
                className="cookies-dialog-link"
                target={"_blank"}
                href="https://atvnewsonline.com/privacy-policy/"
              >
                Privacy Policy
              </a>
            </div>
            <div
              className="cookies-dialog-button"
              onClick={this.setCookiesAccept}
            >
              接受
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default App;
