import React from "react";
// import Swiper JS
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
// import Swiper styles
import "swiper/css";
import "swiper/css/bundle";
import RecommendationInfoCard from "../cards/RecommendationInfoCard";

class RecommendationsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hashtags: [],
      activeRecommendation: null,
    };
  }

  componentDidMount() {
    this.setState({ activeRecommendation: this.props.recommendationPapers[0] });
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.recommendationPapers !== this.props.recommendationPapers) {
    //     if(this.props.recommendationPapers != null){
    //         this.setState({activeRecommendation: this.props.recommendationPapers[0]});
    //     }
    // }
  }

  render() {
    return (
      <div className="full-size">
        {this.state.hashtags.length > 0 ? (
          <div className="recommendation-text">
            与您经常关注#水灾,#政治有关的电子报
          </div>
        ) : null}
        {this.props.recommendationPapers.length > 0 ? (
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={Math.min(3, Math.ceil(window.innerWidth / 450))}
            navigation
            pagination={{
              clickable: true,
            }}
            scrollbar={{
              draggable: true,
            }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}>
            {this.props.recommendationPapers.map((e) => {
              return (
                <SwiperSlide key={"recommendation-" + e.epaper_id}>
                  <img
                    onClick={() => {
                      this.setState({
                        activeRecommendation: e,
                      });
                    }}
                    className={
                      this.state.activeRecommendation != null &&
                      this.state.activeRecommendation.epaper_id === e.epaper_id
                        ? "active-recommendation-card recommendation-image"
                        : "inactive-recommendation-card recommendation-image"
                    }
                    src={e.imagePath}
                    alt="epaper thumbnail"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : null}
        {this.state.activeRecommendation !== null ? (
          <div className="d-flex justify-content-center">
            <RecommendationInfoCard paper={this.state.activeRecommendation} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default RecommendationsTab;
