import ReactGA from "react-ga4";
export function debugPrint(...args) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    console.log(...args);
  }
}

export function convertDateTime(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export function getDate(epoch) {
  var date = new Date(epoch * 1000);
  return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
    "0" + date.getDate()
  ).slice(-2)}`;
}

export function n(n) {
  return n > 9 ? "" + n : "0" + n;
}

export function getTime(epoch) {
  var date = new Date(epoch * 1000);
  return `${n(date.getHours())}:${n(date.getMinutes())}`;
}

export function convertToTime(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function getTodayDateString(offset = 0) {
  var today = new Date();

  if (offset !== 0) {
    today.setDate(today.getDate() + offset);
  }

  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
}

export function getBase64FromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function convertToEpoch(dateTime) {
  var date = dateTime.concat("-00-00-00");
  var d = date.split("-");
  var epoch = new Date(d[0], d[1] - 1, d[2], d[3], d[4], d[5]).valueOf();
  return epoch / 1000;
}

export function isValidMonth(month) {
  return month !== "total" && month.startsWith("m-");
}

export function jsonDataValid(data) {
  if (data === undefined || data === null) {
    return false;
  }
  var jsonData = JSON.parse(data);
  return !(jsonData === undefined || jsonData === null);
}
export function calcMaxNum(array) {
  return Math.max.apply(null, array);
}
export function convert(value) {
  if (value >= 1000000) {
    value = (value / 1000000).toFixed(2) + "M";
  } else if (value >= 1000) {
    value = (value / 1000).toFixed(2) + "K";
  }
  return value;
}

export function getURLParameter(param) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === param) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
}

export function enterEPaperPage(id) {
  window.location.href = getEPaperURL(id);
}

export function getEPaperURL(id) {
  return `/epaper?id=${id}`;
}

export function enterDiscoverPage(searchTag = null) {
  if (searchTag !== null) {
    window.location.href =
      `/discover` + (searchTag === "" ? "" : "?t=" + searchTag);
  } else {
    window.location.href = `/discover`;
  }
}

export function addHashtags(hashtags) {
  var toProcess = getHashtags();
  hashtags.forEach((eachHashtag) => {
    if (Object.keys(toProcess).includes(eachHashtag)) {
      toProcess[eachHashtag] = toProcess[eachHashtag] + 1;
    } else {
      toProcess[eachHashtag] = 1;
    }
  });

  localStorage.setItem("hashtags", JSON.stringify(toProcess));
}

export function getHashtags() {
  var toProcess = {};
  var currentHashtags = localStorage.getItem("hashtags");
  if (currentHashtags !== null && currentHashtags !== undefined) {
    toProcess = JSON.parse(currentHashtags);
  }

  return toProcess;
}

export function openNewPage(page) {
  const newWindow = window.open(page, "_blank", "noopener,noreferrer");
  if (newWindow) {
    newWindow.opener = null;
  }
}

export function youtubeParser(url) {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
}

export function usePageTracking(category, action, label) {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}

export function gaBrowserTracking() {
  ReactGA.send(window.location.pathname + window.location.search);
}
