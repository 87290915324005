import React from "react";
import "./Pages.scss";

import {getEPaperRecommendation, getLegacyEPaper} from "../../utils/Requests";
import NewsCard from "../cards/NewsCard.js";
import FavouritesTab from "../tabs/FavouritesTab";
import {ScaleLoader} from "react-spinners";
import {getEPaperList} from "../../utils/Requests";
import {convertToEpoch, gaBrowserTracking, getHashtags, getURLParameter, usePageTracking} from "../../utils/Utilities.js";
import Logo from "../../images/atv-epaper-logo.png";
import HomeIcon from "../../images/icons/HomeIcon.svg";
import {getTodayDateString} from "../../utils/Utilities";
import APIResult from "../../configs/APIResult";
import RecommendationsTab from "../tabs/RecommendationsTab";
import Footer from "../Footer";
import PageNavigation from "../widgets/PageNavigation";

class DiscoverPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
            searchType: 0,
            startDate: getTodayDateString(-60),
            endDate: getTodayDateString(),
            searchText: "",
            mode: 0,
            hashtags: [],
            recommendation: [],
            recommendationImages: [],
            goToSlide: 0,
            offsetRadius: 2,
            showNavigation: true,
            currentRecommendation: 0,
            epapersSearchResult: [],
            legacyEPapersSearchResult: [],
            legacyEpapers: [],
            loadingResult: false,
            tabs: [
                "建议阅读", "收藏库", "搜索"
            ],
            recommendationPapers: [],
            currentSearchPage: 0,
            totalSearchResult: 0,
            currentLegacyPage: 0,
            totalLegacyResult: 0,
            legacyEpaper: [],
            agreePP: false
        };

        this.itemPerPage = 8;
        this.allFilteredLegacyPage = [];
        this.legacyEPapers = [];
        this.handleResize = this
            .handleResize
            .bind(this);
        this.searchLegacyEPaper = this
            .searchLegacyEPaper
            .bind(this);
    }

    componentDidMount() {
        let agree = localStorage.getItem("pp-agree");
        this.setState({
            agreePP: agree === "1" || agree === 1
        });
        this.getLegacyEpaper();
        this.getEPaperRecommendation();
        window.addEventListener("resize", this.handleResize);
        gaBrowserTracking();

        const tagSearched = getURLParameter("t");
        if (tagSearched) {
            this.setState({
                searchText: tagSearched,
                searchType: 1,
                mode: 2
            }, () => {
                this.search();
            });
        } else {
            this.search();
        }
    }

    async getLegacyEpaper() {
        let result = await getLegacyEPaper();
        if ((result.status = APIResult.SUCCESS)) {
            var legacyEPapers = result;
            legacyEPapers.forEach((eachEPaper) => {
                let ymd = eachEPaper.ymd;
                if (ymd !== null && ymd !== undefined) {
                    let y = ymd.substring(0, 4);
                    let m = ymd.substring(4, 6);
                    let d = ymd.substring(6, 8);
                    let newYMD = `${y}-${m}-${d}`;
                    const date = new Date(newYMD);
                    eachEPaper.timestamp = date.getTime() / 1000;
                }
            });
            this.legacyEPapers = legacyEPapers;
            this.searchLegacyEPaper();
        }
    }

    searchLegacyEPaper(changePageOnly = false) {
        let totalSkip = this.state.currentLegacyPage * this.itemPerPage;
        if (!changePageOnly) {
            this.allFilteredLegacyPage = [];
        }
        var skipCount = 0;
        let start = convertToEpoch(this.state.startDate);
        let end = convertToEpoch(this.state.endDate);
        var resultToShow = [];
        this
            .legacyEPapers
            .forEach((eachEPaper) => {
                if (eachEPaper.timestamp > start && eachEPaper.timestamp < end) {
                    this
                        .allFilteredLegacyPage
                        .push(eachEPaper);
                    if (skipCount > totalSkip) {
                        if (resultToShow.length < this.itemPerPage) {
                            resultToShow.push(eachEPaper);
                        }
                    }
                    skipCount += 1;
                }
            });

        this.setState({legacyEPapersSearchResult: resultToShow, totalLegacyResult: this.allFilteredLegacyPage.length});
    }

    onRecommendationChanged(index) {
        console.log("callback", index);
    }

    handleResize(e) {
        this.setState({width: window.innerWidth});
    }

    async getEPaperRecommendation() {
        var currentHashtags = getHashtags();
        var allCurrentHashtags = Object.keys(currentHashtags);
        var finalHashtag = [];
        var allScores = [];
        Object
            .keys(currentHashtags)
            .forEach((eachHashtag) => {
                allScores.push(currentHashtags[eachHashtag]);
            });
        allScores.sort(function (a, b) {
            return b - a;
        });
        if (allScores.length > 0) {
            allScores.forEach((eachScore) => {
                allCurrentHashtags.forEach((eachHashtag) => {
                    if (currentHashtags[eachHashtag] === eachScore) {
                        if (finalHashtag.length < 2) {
                            finalHashtag.push(eachHashtag);
                        }
                        allCurrentHashtags.splice(allCurrentHashtags.indexOf(eachHashtag), 1);
                    }
                });
            });
        }

        let result = await getEPaperRecommendation(finalHashtag);
        if (result.status === APIResult.SUCCESS) {
            var recommendationsData = [];
            if (result.data.length > 0) {
                recommendationsData = result.data;
                recommendationsData.forEach((eachEPaper) => {
                    eachEPaper.imagePath = eachEPaper.epaper_path + eachEPaper.epaper_thumbnail;
                });
            }
            this.setState({recommendationPapers: recommendationsData, hashtags: finalHashtag});
        }
    }

    async search() {
        this.setState({loadingResult: true, currentSearchPage: 0, currentLegacyPage: 0});
        let result = null;
        if (this.state.searchType === 0) {
            result = await getEPaperList(convertToEpoch(this.state.startDate), convertToEpoch(this.state.endDate) + 24 * 60 * 60, this.state.currentSearchPage, this.itemPerPage, this.state.searchText, "");
        } else {
            result = await getEPaperList(convertToEpoch(this.state.startDate), convertToEpoch(this.state.endDate) + 24 * 60 * 60, this.state.currentSearchPage, this.itemPerPage, "", this.state.searchText);
        }
        if (result.status === APIResult.SUCCESS) {
            if (result.data.length > 0) {
                result.data = result
                    .data
                    .map((v) => Object.assign(v, {seeMore: false}));
                this.setState({epapersSearchResult: result.data});
                this
                    .state
                    .epapersSearchResult
                    .push(this.state.legacyEpaper);
            } else {
                this.setState({epapersSearchResult: []});
                this
                    .state
                    .epapersSearchResult
                    .push(this.state.legacyEpaper);
            }
            this.setState({totalSearchResult: result.total});
        }

        this.setState({loadingResult: false});
    }

    render() {
        return (
            <div className="center white-bg footer-margin" id="discover">
                <div className="home-parent">
                    <img
                        className="logo button"
                        alt="logo"
                        src={Logo}
                        onClick={() => {
                        const newWindow = window.open("https://atvnewsonline.com/", "_blank", "noopener,noreferrer");
                        if (newWindow) {
                            newWindow.opener = null;
                        }
                    }}/>
                    <div className="space-between">
                        <div className="title">探索</div>
                        <img
                            className="search-icon"
                            alt="search icon"
                            src={HomeIcon}
                            onClick={() => {
                            window.location.href = `/`;
                            usePageTracking("navigate", "main", "home");
                        }}/>
                    </div>
                    <div className="divider-v"/>
                    <div className="tab-parent">
                        {this
                            .state
                            .tabs
                            .map((tab, index) => {
                                return (
                                    <div
                                        key={"tab-" + index}
                                        onClick={(e) => {
                                        this.setState({
                                            mode: index
                                        }, usePageTracking("navigate", "main", tab));
                                    }}
                                        className={`${this.state.mode === index
                                        ? "tab-activate"
                                        : "tab-deactivate"}`}>
                                        {tab}
                                    </div>
                                );
                            })}
                    </div>
                    {this.state.mode === 0 && this.state.recommendationPapers.length > 0
                        ? (
                            <div className="full-size">
                                {this.state.hashtags.length > 0
                                    ? (
                                        <div className="hashtag-recommendation">
                                            与您经常关注
                                            <div
                                                style={{
                                                fontWeight: 800,
                                                fontSize: 20
                                            }}>
                                                #{this
                                                    .state
                                                    .hashtags
                                                    .join(", #")}
                                            </div>
                                            有关的电子报
                                        </div>
                                    )
                                    : null}
                                <RecommendationsTab recommendationPapers={this.state.recommendationPapers}/>
                            </div>
                        )
                        : null}
                    {this.state.mode === 1
                        ? (<FavouritesTab className="favourites-result"/>)
                        : null}
                    {this.state.mode === 2
                        ? (
                            <div
                                style={{
                                width: "100%",
                                height: "100%"
                            }}>
                                <div className="ePaperPage-filter">
                                    <div className="title-input">搜索字眼</div>
                                    <div
                                        style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        paddingBottom: "1em",
                                        gap: 10
                                    }}>
                                        <div
                                            style={{
                                            flex: 1
                                        }}
                                            className="filter-parent">
                                            <input
                                                onKeyPress={(ev) => {
                                                usePageTracking("navigate", "main", "search");
                                                if (ev.key === "Enter") {
                                                    ev.preventDefault();
                                                    this.search();
                                                    this.searchLegacyEPaper();
                                                }
                                            }}
                                                className="filter-input"
                                                type="text"
                                                placeholder="搜索"
                                                value={this.state.searchText}
                                                onChange={(e) => {
                                                this.setState({searchText: e.target.value});
                                            }}/>
                                        </div>
                                        <div className="filter-parent">
                                            <select
                                                className="filter-input"
                                                type="text"
                                                value={this.state.searchType}
                                                onChange={(e) => {
                                                this.setState({
                                                    searchType: Number(e.target.value)
                                                });
                                            }}>
                                                <option value="0">标题</option>
                                                <option value="1">关键字</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="date-filter-parent">
                                        <div>
                                            <div className="title-input">开始日期</div>
                                            <div className="filter-parent">
                                                <input
                                                    className="filter-input"
                                                    type="date"
                                                    value={this.state.startDate}
                                                    onChange={(e) => {
                                                    this.setState({
                                                        startDate: e.target.value
                                                    }, this.refresh);
                                                }}/>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="title-input">结束日期</div>
                                            <div className="filter-parent">
                                                <input
                                                    className="filter-input"
                                                    type="date"
                                                    value={this.state.endDate}
                                                    onChange={(e) => {
                                                    this.setState({
                                                        endDate: e.target.value
                                                    }, this.refresh);
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search-wrapper">
                                        <div
                                            className="red-button no-margin start-reading w-100"
                                            onClick={() => {
                                            this.search();
                                            this.searchLegacyEPaper();
                                        }}>
                                            搜索
                                        </div>
                                    </div>
                                </div>

                                <div id="new-epaper" className="sub-title">
                                    搜索结果
                                </div>
                                {!this.state.loadingResult
                                    ? (
                                        <div
                                            className="search-result"
                                            style={{
                                            marginTop: "1.5em"
                                        }}>
                                            {this.state.epapersSearchResult.length > 0
                                                ? (
                                                    <div className="favourites-result">
                                                        {this
                                                            .state
                                                            .epapersSearchResult
                                                            .map((e, index) => {
                                                                return (<NewsCard paper={e} key={"e-paper-card-" + e.publish_date_string}/>);
                                                            })}
                                                    </div>
                                                )
                                                : (
                                                    <div className="text-info text-center w-100">
                                                        暂时没有任何已搜索电子报
                                                    </div>
                                                )}
                                            <div
                                                style={{
                                                margin: 15,
                                                width: "100%"
                                            }}>
                                                <PageNavigation
                                                    currentPage={this.state.currentSearchPage + 1}
                                                    total={Math.ceil(Math.max(this.state.totalSearchResult, 1) / this.itemPerPage)}
                                                    onPageChange={(e) => {
                                                    this.setState({
                                                        currentSearchPage: e - 1
                                                    }, () => {
                                                        this.search();
                                                        let newEpaper = window
                                                            .document
                                                            .getElementById("new-epaper");
                                                        newEpaper.scrollIntoView({block: "start", behavior: "smooth"});
                                                    });
                                                }}/>
                                            </div>
                                        </div>
                                    )
                                    : (
                                        <div
                                            className="latest-news-parent latest-news-image center loader-container"
                                            style={{
                                            width: "100%",
                                            boxSizing: "border-box"
                                        }}>
                                            <ScaleLoader color="red"/>
                                        </div>
                                    )}

                                {this.state.legacyEPapersSearchResult.length > 0
                                    ? (
                                        <div>
                                            <div id="classic-epaper" className="sub-title">
                                                经典版电子报
                                            </div>
                                            {!this.state.loadingResult
                                                ? (
                                                    <div
                                                        className="search-result"
                                                        style={{
                                                        marginTop: "1.5em"
                                                    }}>
                                                        {this.state.legacyEPapersSearchResult.length > 0
                                                            ? (
                                                                <div className="favourites-result">
                                                                    {this
                                                                        .state
                                                                        .legacyEPapersSearchResult
                                                                        .map((legacyEPaper, index) => {
                                                                            return (<NewsCard
                                                                                type={1}
                                                                                url={"https://epaper.atvnewsonline.com/" + legacyEPaper.url}
                                                                                paper={{
                                                                                epaper_id: 0,
                                                                                publish_date_string: "",
                                                                                epaper_thumbnail: "https://epaper.atvnewsonline.com/" + legacyEPaper.cover,
                                                                                title: legacyEPaper.name,
                                                                                hashtags: []
                                                                            }}
                                                                                key={"e-paper-card-" + legacyEPaper.name}/>);
                                                                        })}
                                                                </div>
                                                            )
                                                            : (
                                                                <div className="text-info text-center w-100">
                                                                    暂时没有任何已搜索电子报
                                                                </div>
                                                            )}
                                                        <div
                                                            style={{
                                                            margin: 15,
                                                            width: "100%"
                                                        }}>
                                                            <PageNavigation
                                                                currentPage={this.state.currentLegacyPage + 1}
                                                                total={Math.ceil(Math.max(this.state.totalLegacyResult, 1) / this.itemPerPage)}
                                                                onPageChange={(e) => {
                                                                this.setState({
                                                                    currentLegacyPage: e - 1
                                                                }, () => {
                                                                    this.searchLegacyEPaper();
                                                                    let classic = window
                                                                        .document
                                                                        .getElementById("classic-epaper");
                                                                    classic.scrollIntoView({block: "start", behavior: "smooth"});
                                                                });
                                                            }}/>
                                                        </div>
                                                    </div>
                                                )
                                                : (
                                                    <div
                                                        className="latest-news-parent latest-news-image center loader-container"
                                                        style={{
                                                        width: "100%",
                                                        boxSizing: "border-box"
                                                    }}>
                                                        <ScaleLoader color="red"/>
                                                    </div>
                                                )}
                                        </div>
                                    )
                                    : null}
                            </div>
                        )
                        : null}
                </div>
                <Footer/>
            </div>
        );
    }
}

export default DiscoverPage;
