const APIResult = {
  TIME_OUT: -1,
  FAILED: 0,
  SUCCESS: 1,
  INVALID: 5,

  // GET HOMEPAGE
  INVALID_SESSION: 1002,

  //RESET PASSWORD

  RESET_PASSWORD_SUCCESS: 7001,
  RESET_PASSWORD_MAIL_SENT: 7002,
  RESET_PASSWORD_FAILED: 7003,
  RESET_LINK_INVALID: 7004,
  RESET_LINK_EXPIRED: 7005,
};

export default APIResult;
