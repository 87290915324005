import React from "react";
import {
  enterEPaperPage,
  enterDiscoverPage,
  getEPaperURL,
  usePageTracking,
} from "../../utils/Utilities.js";
import CloseItemIcon from "../../images/icons/CloseItemIcon.svg";
import FavouriteIcon from "../../images/icons/HeartFullIcon.svg";
import UnfavouriteItemIcon from "../../images/icons/HeartEmptyIcon.svg";
import MoreVerticalIcon from "../../images/icons/MoreIconBlack.svg";
import ShareIcon from "../../images/icons/ShareIcon.svg";
import { RWebShare } from "react-web-share";

class RecommendationInfoCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMore: false,
      lastPageRead: 0,
      totalPage: 0,
      isFavourite: false,
      
    };
  }

  componentDidMount() {
    this.updateIsFavourite();
    this.getPageRead();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.paper.epaper_id !== this.props.paper.epaper_id) {
      this.updateIsFavourite();
      this.getPageRead();
      this.setState({ showMore: false });
    }
  }

  getPageRead() {
    const ongoingPapersFromLocalStorage = localStorage.getItem("ongoingPapers");
    if (ongoingPapersFromLocalStorage && ongoingPapersFromLocalStorage.length) {
      var array = JSON.parse(ongoingPapersFromLocalStorage);
      const index = array
        .map(function (e) {
          return e.epaper_id;
        })
        .indexOf(this.props.paper.epaper_id.toString());
      if (index > -1) {
        this.setState({
          lastPageRead: array[index].page,
          totalPage: array[index].outOf,
        });
      }
    }
  }

  removeFromFavourites() {
    const favouritesFromLocalStorage = localStorage.getItem("favourites");
    if (favouritesFromLocalStorage && favouritesFromLocalStorage.length) {
      const array = JSON.parse(favouritesFromLocalStorage);
      const index = array
        .map(function (e) {
          return e.epaper_id;
        })
        .indexOf(this.props.paper.epaper_id);

      // Remove id from array
      if (index > -1) {
        array.splice(index, 1);
      }

      this.setState({ isFavourite: false }, () => {
        localStorage.setItem("favourites", JSON.stringify(array));
      });
    }
    this.setState({ showMore: false });
  }

  addToFavourites() {
    const favouritesFromLocalStorage = localStorage.getItem("favourites");
    if (favouritesFromLocalStorage && favouritesFromLocalStorage.length) {
      const array = JSON.parse(favouritesFromLocalStorage);
      this.setState({ isFavourite: true }, () => {
        localStorage.setItem(
          "favourites",
          JSON.stringify([...array, this.props.paper])
        );
      });
    } else {
      this.setState({ isFavourite: true }, () => {
        localStorage.setItem("favourites", JSON.stringify([this.props.paper]));
      });
    }

    this.setState({ showMore: false });
  }

  updateIsFavourite() {
    const favouritesFromLocalStorage = localStorage.getItem("favourites");
    if (favouritesFromLocalStorage && favouritesFromLocalStorage.length) {
      const array = JSON.parse(favouritesFromLocalStorage);
      const index = array
        .map(function (e) {
          return e.epaper_id;
        })
        .indexOf(this.props.paper.epaper_id);
      if (index > -1) {
        this.setState({
          isFavourite: true,
        });
      } else {
        this.setState({
          isFavourite: false,
        });
      }
    }
  }

  render() {
    return (
      <div className="recommendation-info-card">
        <div className="top-row">
          <div className="e-paper-card-title">{this.props.paper.title}</div>
          <div className="see-more-dropdown">
            <img
              src={MoreVerticalIcon}
              alt="more"
              className="see-more-icon"
              onClick={() => {
                this.setState({
                  showMore: !this.state.showMore,
                });
              }}></img>
          </div>
        </div>
        <div className="e-paper-info-hashtags">
          {this.props.paper.hashtags.map((h) => {
            return (
              <div
                key={`hashtag-${this.props.paper.publish_date_string}-${h.hashtag_name}`}
                className="e-paper-info-hashtag"
                onClick={() => {
                  enterDiscoverPage(h.hashtag_name);
                }}>
                {h.hashtag_name}
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-between">
          <div className="e-paper-card-date">
            {this.props.paper.publish_date_string}
          </div>
          {this.state.lastPageRead > 0 ? (
            <div className="e-paper-card-date e-paper-card-ongoing-page">
              {" "}
              已阅读至 {this.state.lastPageRead} / {this.state.totalPage} 页{" "}
            </div>
          ) : null}
        </div>
        <div
          className="red-button no-margin start-reading"
          onClick={() => {
            enterEPaperPage(this.props.paper.epaper_id);
            usePageTracking(
              "navigate",
              "news",
              this.props.paper.title
            );
          }}>
          开始阅读
        </div>
        {this.state.showMore ? (
          <div
            className="more-parent"
            style={{
              left: "0",
            }}>
            <div>
              {" "}
              {this.state.isFavourite ? (
                <div
                  className="more-items-parent add-to-favourite"
                  onClick={() => {
                    this.removeFromFavourites();
                    usePageTracking(
                      "feature",
                      "unfavourite",
                      this.props.paper.title
                    );
                  }}>
                  <img
                    className="more-items-icon"
                    src={UnfavouriteItemIcon}
                    alt="unfavourite"
                  />
                  <div className="more-items-text">取消收藏</div>
                </div>
              ) : (
                <div
                  className="more-items-parent add-to-favourite"
                  onClick={() => {
                    this.addToFavourites();
                    usePageTracking(
                      "feature",
                      "favourite",
                      this.props.paper.title
                    );
                  }}>
                  <img
                    className="more-items-icon"
                    src={FavouriteIcon}
                    alt="favourite"
                  />
                  <div className="more-items-text">收藏</div>
                </div>
              )}
            </div>
            <RWebShare
              data={{
                text: `电子报 - ${this.props.paper.title}`,
                url: `${window.location.origin}${getEPaperURL(
                  this.props.paper.epaper_id
                )}`,
                title: `电子报 - ${this.props.paper.title}`,
              }}
              onClick={() => console.log("已成功分享")}>
              <div className="more-items-parent">
                <img
                  className="more-items-icon"
                  src={ShareIcon}
                  alt="favourite"
                />
                <div className="more-items-text">分享</div>
              </div>
            </RWebShare>
            <div
              className="more-items-parent"
              onClick={() => {
                this.setState({ showMore: false });
              }}>
              <img
                className="more-items-icon half-opacity"
                src={CloseItemIcon}
                alt="favourite"
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default RecommendationInfoCard;
