import React from "react";
import CloseItemIcon from "../../images/icons/CloseItemIcon.svg";
import FavouriteIcon from "../../images/icons/HeartFullIcon.svg";
import ShareIcon from "../../images/icons/ShareIcon.svg";
import UnfavouriteItemIcon from "../../images/icons/HeartEmptyIcon.svg";
import MoreIcon from "../../images/icons/MoreIcon.png";
import { RWebShare } from "react-web-share";
import PropTypes from "prop-types";
import { usePageTracking } from "../../utils/Utilities";

class LatestNewsCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMore: false,
      isFavourite: false,
    };

    this.getEPaperURL = this.getEPaperURL.bind(this);
  }

  componentDidMount() {
    this.updateIsFavourite();
  }

  removeFromFavourites() {
    const favouritesFromLocalStorage = localStorage.getItem("favourites");
    if (favouritesFromLocalStorage && favouritesFromLocalStorage.length) {
      const array = JSON.parse(favouritesFromLocalStorage);
      const index = array
        .map(function (e) {
          return e.epaper_id;
        })
        .indexOf(this.props.paper.epaper_id);

      // Remove id from array
      if (index > -1) {
        array.splice(index, 1);
      }

      this.setState(
        {
          isFavourite: false,
        },
        () => {
          localStorage.setItem("favourites", JSON.stringify(array));
        }
      );
    }
    this.setState({ showMore: false });
  }

  addToFavourites() {
    const favouritesFromLocalStorage = localStorage.getItem("favourites");
    if (favouritesFromLocalStorage && favouritesFromLocalStorage.length) {
      const array = JSON.parse(favouritesFromLocalStorage);
      this.setState(
        {
          isFavourite: true,
        },
        () => {
          localStorage.setItem(
            "favourites",
            JSON.stringify([...array, this.props.paper])
          );
        }
      );
    } else {
      this.setState(
        {
          isFavourite: true,
        },
        () => {
          localStorage.setItem(
            "favourites",
            JSON.stringify([this.props.paper])
          );
        }
      );
    }

    this.setState({ showMore: false });
  }

  updateIsFavourite() {
    const favouritesFromLocalStorage = localStorage.getItem("favourites");
    if (favouritesFromLocalStorage && favouritesFromLocalStorage.length) {
      const array = JSON.parse(favouritesFromLocalStorage);
      const index = array
        .map(function (e) {
          return e.epaper_id;
        })
        .indexOf(this.props.paper.epaper_id);
      if (index > -1) {
        this.setState({ isFavourite: true });
      } else {
        this.setState({ isFavourite: false });
      }
    }
  }

  getEPaperURL() {
    var url = "";
    if (this.props.type === 0) {
      url = `/epaper?id=${this.props.paper.epaper_id}`;
      
    }
    if (this.props.type === 1) {
      url = this.props.url;
    }

    return url;
  }

//   getCurrentPageFromEPaper = (childData) =>{
//     var data = childData;
//     return data;
// }

  enterEPaperPage() {
    window.location.href = this.getEPaperURL();
  }

  getReadTime() {
    return Math.floor(this.props.paper.page_count * 1.75);
  }

  getHashtag() {
    var returnString = "";
    this.props.paper.hashtags.forEach((eachHashtag) => {
      returnString += `#${eachHashtag.hashtag_name} `;
    });
    return returnString;
  }

  render() {
    return (
      <div className="card-wrapper">
        <div className="latest-news-parent">
          <img
            className="latest-news-image"
            src={
              this.props.type === 0
                ? this.props.paper.epaper_path +
                  this.props.paper.epaper_thumbnail
                : this.props.paper.epaper_thumbnail
            }
            alt="latest news"
          />
          <div className="black-fade-bottom" />
          <div className="latest-news-info-parent">
            <div className="latest-news-info-date">
              {this.props.paper.publish_date_string}
            </div>
            <div className="latest-news-info-title">
              {this.props.paper.title}
            </div>
            <div className="latest-news-info-hashtag">{this.getHashtag()}</div>
            {this.props.type === 0 ? (
              <div className="latest-news-info-readtime">
                约{this.getReadTime()}分钟阅读时间
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
              }}>
              <div
                style={{
                  width: "100%",
                }}
                className="red-button"
                onClick={() => {
                  this.enterEPaperPage();
                  usePageTracking(
                    "navigate",
                    "news",
                    "latest_news"
                  );
                }}>
                开始阅读
              </div>
              <img
                style={{
                  position: "unset",
                }}
                className="more-icon-button"
                src={MoreIcon}
                onClick={() => {
                  this.setState({ showMore: true });
                }}
                alt="see more"
              />
            </div>
          </div>
          {this.state.showMore ? (
            <div className="more-parent">
              {this.props.type === 0 ? (
                <div>
                  {this.state.isFavourite ? (
                    <div
                      className="more-items-parent add-to-favourite"
                      onClick={() => {
                        this.removeFromFavourites(this.props.paper.epaper_id);
                        usePageTracking(
                          "feature",
                          "unfavourite",
                          this.props.paper.title
                        );
                      }}>
                      <img
                        className="more-items-icon"
                        src={UnfavouriteItemIcon}
                        alt="unfavourite"
                      />
                      <div className="more-items-text">取消收藏</div>
                    </div>
                  ) : (
                    <div
                      className="more-items-parent add-to-favourite"
                      onClick={() => {
                        this.addToFavourites(this.props.paper);
                        usePageTracking(
                          "feature",
                          "favourite",
                          this.props.paper.title
                        );
                      }}>
                      <img
                        className="more-items-icon"
                        src={FavouriteIcon}
                        alt="favourite"
                      />
                      <div className="more-items-text">收藏</div>
                    </div>
                  )}
                </div>
              ) : null}
              <RWebShare
                data={{
                  text: `电子报 - ${this.props.paper.title}`,
                  url: this.getEPaperURL(),
                  title: `电子报 - ${this.props.paper.title}`,
                }}
                onClick={() =>
                  usePageTracking(
                    "feature",
                    "share",
                    this.props.paper.title
                  )
                }>
                <div className="more-items-parent">
                  <img
                    className="more-items-icon"
                    src={ShareIcon}
                    alt="favourite"
                  />
                  <div className="more-items-text">分享</div>
                </div>
              </RWebShare>
              <div
                className="more-items-parent"
                onClick={() => {
                  this.setState({ showMore: false });
                }}>
                <img
                  className="more-items-icon half-opacity"
                  src={CloseItemIcon}
                  alt="favourite"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

LatestNewsCard.propTypes = {
  paper: PropTypes.object,
  type: PropTypes.number,
  url: PropTypes.string,
};

LatestNewsCard.defaultProps = {
  paper: {
    epaper_id: 0,
    publish_date_string: "-",
    epaper_thumbnail: "",
    title: "-",
    hashtags: [],
  },
  type: 0,
  url: "",
};

export default LatestNewsCard;
