import React from "react";
import "./Pages.scss";
import queryString from "query-string";
import { addReadCount, getEPaperDetails } from "../../utils/Requests";
import APIResult from "../../configs/APIResult";
import {
  addHashtags,
  getDate,
  gaBrowserTracking,
  youtubeParser,
} from "../../utils/Utilities";
import FlipSoundEffect from "../../assets/sounds/flip-effect.mp3";
import BackIcon from "../../images/icons/BackIcon.png";
import ShareIcon from "../../images/icons/ShareIcon.svg";
import FirstPageIcon from "../../images/icons/FirstPageIcon.png";
import PrevPageIcon from "../../images/icons/PrevPageIcon.png";
import ZoomInIcon from "../../images/icons/ZoomInIcon.png";
import ZoomOutIcon from "../../images/icons/ZoomOutIcon.png";
import NextPageIcon from "../../images/icons/NextPageIcon.png";
import LastPageIcon from "../../images/icons/LastPageIcon.png";
import CloseItemIcon from "../../images/icons/CloseItemIcon.svg";
import AllPagesIcon from "../../images/icons/AllPagesIcon.svg";
import Turn from "../widgets/Turn";
import $ from "jquery";
import { ePaperHeight, ePaperWidth } from "../../configs/EPaper";
import ReactDocumentTitle from "react-document-title";
import { GridLoader } from "react-spinners";
import Draggable from "react-draggable";
import ATVLogoButton from "../../images/logo_192.png";
import { RWebShare } from "react-web-share";
import "../../utils/custom-events.js";
import DownloadIcon from "../../images/icons/download-atv-app_icon.svg";

class EPaperPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
      prevZoomState: 1,
      zoomState: 1,
      offsetX: 0,
      offsetY: 0,
      ableFirstPage: false,
      ablePrevPage: false,
      ableZoomOut: false,
      ableZoomIn: true,
      ableNextPage: false,
      ableLastPage: false,
      title: "",
      render: 0,
      images: [],
      thumbnails: [],
      width: 0,
      height: 0,
      resizing: false,
      shareOptions: false,
      forceCloseShareOptions: false,
      options: {
        width:
          ((window.innerHeight - 128) / ePaperHeight) *
          (ePaperWidth * (window.innerWidth > window.innerHeight ? 2 : 1)),
        height: window.innerHeight - 128,
        autoCenter: true,
        display: "single",
        acceleration: true,
        elevation: 50,
        gradients: !$.isTouch,
        when: {
          turned: this.updateIcons,
        },
      },
      scaleAnimation: "",
      showVideo: false,
      showYoutube: false,
      videoURL: "",
      youtubeURL: "",
      showPageNavigation: false,
      allowPinch: true,
    };

    this.resizing = false;
    this.resizeTime = 0;
    this.resizeCallInterval = null;
    this.verticalPadding = 128;
    this.zoomPower = 0.5;
    this.updateIcons = this.updateIcons.bind(this);
    this.selectPage = this.selectPage.bind(this);
    this.openSelection = this.openSelection.bind(this);
    this.openNavigationList = this.openNavigationList.bind(this);
    this.zoomImage = this.zoomImage.bind(this);
    this.goToSavedProgress = this.goToSavedProgress.bind(this);
    this.myRef = React.createRef();
    this.startX = 0;
    this.startY = 0;

    this.evCache = [];
    this.prevDiff = -1;
    this.checkPinch = this.checkPinch.bind(this);
    this.allowPinch = true;
    this.currentZoom = Math.round(window.devicePixelRatio * 100);

    this.currentZoomHeight = window.innerHeight;
    this.currentZoomWidth = window.innerWidth;
  }

  componentDidMount() {
    this.ongoingPapersFromLocalStorage = localStorage.getItem("ongoingPapers");
    this.handleResize();
    let urlParams = queryString.parse(this.props.location.search);

    if ("id" in urlParams) {
      this.ePaperID = urlParams.id;
      this.retrieveEPaperDetails();
    }
    gaBrowserTracking();

    window.addEventListener(
      "swiped-right",
      () => {
        if (window.innerWidth < 1200) {
          return;
        }
        if (
          this.currentZoomWidth / window.innerWidth < 1.1 &&
          this.currentZoomHeight / window.innerHeight < 1.1 &&
          !this.state.showPageNavigation &&
          this.allowPinch
        ) {
          $(this.ePaper).turn("previous");
        }
      },
      false
    );
    window.addEventListener(
      "swiped-left",
      () => {
        if (window.innerWidth < 1200) {
          return;
        }
        if (
          this.currentZoomWidth / window.innerWidth < 1.1 &&
          this.currentZoomHeight / window.innerHeight < 1.1 &&
          !this.state.showPageNavigation &&
          this.allowPinch
        ) {
          $(this.ePaper).turn("next");
        }
      },
      false
    );
  }

  addOrUpdateUrlParam(name, value) {
    var search = window.location.search;
    var regex = new RegExp("[&\\?]" + name + "=");
    if (regex.test(search)) {
      regex = new RegExp("([&\\?])" + name + "=\\d+");
      window.location.search = search.replace(regex, "$1" + name + "=" + value);
    } else {
      if (search.indexOf("?") > -1)
        window.location.search = search + "&" + name + "=" + value;
      else window.location.search = search + "?" + name + "=" + value;
    }
  }

  checkPinch() {
    const el = document.getElementById("target");
    el.onpointerdown = this.pointerdown_handler;
    el.onpointermove = this.pointermove_handler;
    el.onpointerup = this.pointerup_handler;
    el.onpointercancel = this.pointerup_handler;
    el.onpointerout = this.pointerup_handler;
    el.onpointerleave = this.pointerup_handler;
  }
  pointermove_handler = (ev) => {
    ev = 0;
    for (let i = 0; i < this.evCache.length; i++) {
      if (ev.pointerId === this.evCache[i].pointerId) {
        this.evCache[i] = ev;
        break;
      }
    }
    if (this.evCache.length === 2) {
      const curDiff = Math.abs(
        this.evCache[0].clientX - this.evCache[1].clientX
      );
      this.allowPinch = false;
      if (this.prevDiff > 0) {
        this.allowPinch = false;
        if (curDiff > this.prevDiff) {
          this.setState({ allowPinch: false });
        }
      }
      this.prevDiff = curDiff;
    }
  };

  remove_event = (ev) => {
    // Remove this event from the target's cache
    for (let i = 0; i < this.evCache.length; i++) {
      if (this.evCache[i].pointerId === ev.pointerId) {
        this.evCache.splice(i, 1);
        break;
      }
    }
  };

  pointerdown_handler = (ev) => {
    this.evCache.push(ev);
    this.allowPinch = true;
  };

  pointerup_handler = (ev) => {
    this.remove_event(ev);
    if (this.evCache.length < 2) {
      this.prevDiff = -1;
    }
  };

  playFlipSound() {
    let audio = new Audio(FlipSoundEffect);
    audio.play();
  }

  turnPageFromUrlParams() {
    let urlParams = queryString.parse(this.props.location.search);
    if ("p" in urlParams) {
      $(this.ePaper).turn("page", urlParams.p);
    }
  }

  handleResize = (e) => {
    this.resizeTime = Date.now();
    if (!this.resizing) {
      this.resizing = true;
      this.resizeCallInterval = setInterval(() => {
        var width =
          ((window.innerHeight - this.verticalPadding) / ePaperHeight) *
          (ePaperWidth * 2);
        var height = window.innerHeight - this.verticalPadding;

        if (width > window.innerWidth) {
          width = window.innerWidth;
          height = (window.innerWidth / 2 / ePaperWidth) * ePaperHeight;
        }

        if (window.innerWidth < window.innerHeight) {
          width =
            ((window.innerHeight - this.verticalPadding) / ePaperHeight) *
            ePaperWidth;
          height = window.innerHeight - this.verticalPadding;

          if (width > window.innerWidth) {
            width = window.innerWidth;
            height = (window.innerWidth / ePaperWidth) * ePaperHeight;
          }
        }

        this.setState({ width: width, height: height });

        let options = {
          corners: {
            backward: [],
            forward: [],
            all: [],
          },
          width: width,
          height: height,
          autoCenter: true,
          display: window.innerWidth > window.innerHeight ? "double" : "single",
          acceleration: true,
          elevation: 50,
          gradients: !$.isTouch,
          when: {
            turned: this.updateIcons,
          },
        };

        if (Date.now() - this.resizeTime > 225) {
          this.setState(
            {
              render: this.state.render + 1,
              options: options,
              resizing: false,
            },
            () => {
              // $(this.ePaper).turn("display", window.innerWidth > window.innerHeight ?
              // "double" : "single");
            }
          );
          clearInterval(this.resizeCallInterval);
          this.resizing = false;
        }
      }, 50);
    }
    if (!this.state.resizing) {
      this.setState({ resizing: false });
    }
  };

  zoomImage(zoom) {
    var animation = "";

    zoom = Math.max(1, zoom);
    zoom = Math.min(2.5, zoom);
    if (zoom < this.state.zoomState) {
      switch (zoom) {
        default:
        case 1:
          animation = "descale-1";
          break;
        case 1.5:
          animation = "descale-2";
          break;
        case 2:
          animation = "descale-3";
          break;
      }
    }
    if (zoom > this.state.zoomState) {
      switch (zoom) {
        default:
        case 1.5:
          animation = "scale-1";
          break;
        case 2:
          animation = "scale-2";
          break;
        case 2.5:
          animation = "scale-3";
          break;
      }
    }

    this.setState({
      scaleAnimation: animation,
      zoomState: zoom,
      ableZoomIn: zoom < 2.5,
      ableZoomOut: zoom > 1,
    });
  }

  goToSavedProgress() {
    try {
      let ePaperList = JSON.parse(this.ongoingPapersFromLocalStorage);
      ePaperList.forEach((eachEPaper) => {
        if (eachEPaper.epaper_id === this.ePaperID) {
          $(this.ePaper).turn("page", eachEPaper.page);
        }
      });
    } catch (e) {}
  }

  updateIcons(e, page) {
    const ongoingPapersFromLocalStorage = localStorage.getItem("ongoingPapers");
    const pageData = {
      epaper_id: this.ePaperID,
      page: page,
      outOf: this.state.images.length,
    };

    if (ongoingPapersFromLocalStorage && ongoingPapersFromLocalStorage.length) {
      var array = JSON.parse(ongoingPapersFromLocalStorage);
      const index = array
        .map(function (e) {
          return e.epaper_id;
        })
        .indexOf(this.ePaperID);

      // Update array
      if (index > -1) {
        array[index] = pageData;
      } else {
        array = [...array, pageData];
      }

      localStorage.setItem("ongoingPapers", JSON.stringify(array));
    } else {
      localStorage.setItem("ongoingPapers", JSON.stringify([pageData]));
    }

    this.setState({
      currentPage: page,
      ableFirstPage: page > 1,
      ablePrevPage: page > 1,
      ableNextPage: page < this.state.images.length,
      ableLastPage: page < this.state.images.length,
    });
  }

  getCurrentPage(number) {
    return (
      window.location.protocol +
      "//" +
      window.location.host +
      `/epaper?id=${this.ePaperID}&p=${number}`
    );
  }
  getEntirePage() {
    return (
      window.location.protocol +
      "//" +
      window.location.host +
      `/epaper?id=${this.ePaperID}`
    );
  }

  async retrieveEPaperDetails() {
    addReadCount(this.ePaperID);
    var result = await getEPaperDetails(this.ePaperID);
    if (result.status === APIResult.SUCCESS) {
      var ePaperImages = [];
      var thumbnails = [];
      result.data.epaper_img.forEach((eachImage) => {
        ePaperImages.push(result.data.epaper_path + eachImage);
      });
      result.data.epaper_img_thumbnail.forEach((eachImage) => {
        thumbnails.push(result.data.epaper_path + eachImage);
      });
      var hashtags = [];
      result.data.hashtags.forEach((eachHashtag) => {
        hashtags.push(eachHashtag.hashtag_name);
      });

      addHashtags(hashtags);

      this.setState(
        {
          images: ePaperImages,
          thumbnails: thumbnails,
          tags: hashtags,
          title: result.data.title,
          hashtags: hashtags.join(),
          editMode: ePaperImages.length > 0 ? 1 : 0,
          bracketsInfo: result.data.coor,
          date: getDate(result.data.publish_timestamp),
          status: result.data.status,
        },
        () => {
          this.goToSavedProgress();
          this.turnPageFromUrlParams();
        }
      );
    }
  }

  backToHomePage() {
    window.location.href = `/`;
  }

  checkClick(e, index) {
    var rect = e.target.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    let ePaperImage = document.getElementById(`e-paper-p${index}`);
    let width = ePaperImage.offsetWidth;
    let height = ePaperImage.offsetHeight;
    let xP = (x / width) * 100;
    let yP = (y / height) * 100;
    this.state.bracketsInfo.forEach((e) => {
      if (
        xP >= Number(e.left) &&
        xP <= Number(e.left) + Number(e.width) &&
        yP >= Number(e.top) &&
        yP <= Number(e.top) + Number(e.height) &&
        index === e.page
      ) {
        if (e.area_type === 0) {
          const newWindow = window.open(
            e.area_url,
            "_blank",
            "noopener,noreferrer"
          );
          if (newWindow) newWindow.opener = null;
        }
      }
    });
  }

  runBracket(e) {
    if (e.area_url.includes("youtube.com") || e.area_url.includes("youtu.be")) {
      let youtubeID = youtubeParser(e.area_url);
      this.setState({ showYoutube: true, youtubeURL: youtubeID });
    } else {
      if (e.area_type === 0 || e.area_type === "0") {
        const newWindow = window.open(
          e.area_url,
          "_blank",
          "noopener,noreferrer"
        );
        if (newWindow) {
          newWindow.opener = null;
        }
      }
      if (e.area_type === 1 || e.area_type === "1") {
        this.setState({ showVideo: true, videoURL: e.area_url });
      }
    }
  }

  selectPage(pageIndex) {
    $(this.ePaper).turn("page", pageIndex);
    this.setState({
      showPageNavigation: !this.state.showPageNavigation,
    });
  }

  openSelection(index) {
    var borderColor = "";
    if (this.state.currentPage === index) {
      borderColor = "#FF4349";
    }
    return borderColor;
  }

  openNavigationList() {
    this.setState({
      showPageNavigation: !this.state.showPageNavigation,
    });
    var element = document.getElementById("test");
    element.scrollIntoView();
    // window.scrollTo(0, this.myRef.current);
  }

  render() {
    return (
      <ReactDocumentTitle title={this.state.title + " - 亚视新闻东南亚电子报"}>
        <div className="e-paper-parent" id="target" onLoad={this.checkPinch}>
          <div className="e-paper-top-bar">
            <img
              className="clickable-button icon-button"
              src={BackIcon}
              alt="back"
              onClick={this.backToHomePage}
            />

            <div className="e-paper-date">{this.state.date}</div>
            <div
              onClick={() =>
                this.setState(
                  { shareOptions: !this.state.shareOptions },
                  console.log("已成功分享")
                )
              }
            >
              <img
                className="clickable-button icon-button"
                src={ShareIcon}
                alt="share"
                style={{
                  padding: 4,
                }}
              />
            </div>
          </div>
          <div className="top-black-fade" />
          <div className="bottom-black-fade" />{" "}
          {this.state.resizing ? (
            <div
              className="full-size center fill-height"
              style={{
                color: "white",
                flexDirection: "column",
              }}
            >
              <GridLoader color={"white"} />
              <div className="m-a-m more-items-text">载入中...</div>
            </div>
          ) : this.state.images.length > 0 ? (
            window.innerWidth > 1200 ? (
              <Draggable
                cancel={".bracket"}
                bounds={{
                  top: (-this.state.zoomState * this.state.width) / 2,
                  left: (-this.state.zoomState * this.state.width) / 2,
                  right: (this.state.zoomState * this.state.width) / 2,
                  bottom: (this.state.zoomState * this.state.width) / 2,
                }}
              >
                <div className="fill-height">
                  <div
                    className="e-paper-page"
                    id={this.state.render}
                    style={{
                      transform: `scale(${this.state.zoomState})`,
                      animationName:
                        this.state.scaleAnimation === ""
                          ? "unset"
                          : this.state.scaleAnimation,
                      animationDuration: "0.25s",
                      animationTimingFunction: "ease-in",
                    }}
                  >
                    <div
                      className="e-paper-wrapper"
                      style={{
                        width: this.state.width,
                        height: this.state.height,
                        transform: `translate(${this.state.offsetX}px, ${this.state.offsetY}px)`,
                      }}
                    >
                      <Turn
                        options={this.state.options}
                        className="magazine"
                        passBack={(e) => {
                          this.ePaper = e;
                        }}
                      >
                        {this.state.images.map((page, index) => (
                          <div key={index} className="page relative">
                            <img
                              style={{
                                borderRadius: "0.5em",
                                pointerEvents: "none",
                              }}
                              id={`e-paper-p${index}`}
                              src={page}
                              alt=""
                            />{" "}
                            {this.state.bracketsInfo.map((e, i) => {
                              if (e.page === index) {
                                return (
                                  <div
                                    className="max-size absolute"
                                    key={"bracket-" + e.area_url + "-" + i}
                                    style={{
                                      pointerEvents: "all",
                                    }}
                                  >
                                    <div
                                      onClick={() => {
                                        this.runBracket(e);
                                      }}
                                      className="bracket"
                                      style={{
                                        width: e.width + "%",
                                        height: e.height + "%",
                                        top: e.top + "%",
                                        left: e.left + "%",
                                        position: "absolute",
                                        pointerEvents: "visible",
                                        zIndex: 500,
                                      }}
                                    />
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </div>
                        ))}
                      </Turn>
                    </div>
                  </div>
                </div>
              </Draggable>
            ) : (
              <Draggable
                cancel={".bracket"}
                bounds={{
                  top: (-this.state.zoomState * this.state.width) / 2,
                  left: (-this.state.zoomState * this.state.width) / 2,
                  right: (this.state.zoomState * this.state.width) / 2,
                  bottom: (this.state.zoomState * this.state.width) / 2,
                }}
              >
                <div className="fill-height">
                  <div
                    className="e-paper-page"
                    id={this.state.render}
                    style={{
                      transform: `scale(${this.state.zoomState})`,
                      animationName:
                        this.state.scaleAnimation === ""
                          ? "unset"
                          : this.state.scaleAnimation,
                      animationDuration: "0.25s",
                      animationTimingFunction: "ease-in",
                    }}
                  >
                    <div
                      className="e-paper-wrapper"
                      style={{
                        width: this.state.width,
                        height: this.state.height,
                        transform: `translate(${this.state.offsetX}px, ${this.state.offsetY}px)`,
                        // touchAction: "none"
                      }}
                    >
                      <Turn
                        options={this.state.options}
                        className="magazine"
                        blockFlip={true}
                        passBack={(e) => {
                          this.ePaper = e;
                        }}
                      >
                        {this.state.images.map((page, index) => (
                          <div key={index} className="page relative">
                            <img
                              loading="lazy"
                              style={{
                                borderRadius: "0.5em",
                                pointerEvents: "none",
                              }}
                              id={`e-paper-p${index}`}
                              src={page}
                              alt=""
                            />{" "}
                            {this.state.bracketsInfo.map((e, i) => {
                              if (e.page === index) {
                                return (
                                  <div
                                    className="max-size absolute"
                                    background="red"
                                    key={"bracket-" + e.area_url + "-" + i}
                                    style={{
                                      pointerEvents: "visible",
                                    }}
                                  >
                                    <div
                                      onClick={() => {
                                        if (
                                          this.state.currentPage ===
                                          e.page + 1
                                        ) {
                                          this.runBracket(e);
                                        }
                                      }}
                                      className="bracket"
                                      style={{
                                        width: e.width + "%",
                                        height: e.height + "%",
                                        top: e.top + "%",
                                        left: e.left + "%",
                                        position: "absolute",
                                        pointerEvents: "visible",
                                        zIndex: 100,
                                      }}
                                    />
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </div>
                        ))}
                      </Turn>
                    </div>
                  </div>
                </div>
              </Draggable>
            )
          ) : null}
          <div
            onClick={() => {
              this.setState({
                showPageNavigation: !this.state.showPageNavigation,
              });
            }}
            className={`dark-backdrop ${
              this.state.showPageNavigation ? "fade-in" : "fade-out"
            }`}
          />
          <img
            className="button p-a-s btm-left brighten-on-hover"
            alt="homepage"
            src={ATVLogoButton}
            style={{
              height: "2.8em",
              width: "2.8em",
            }}
          />
          <div
            className="download-app-button btm-right button p-a-s "
            style={{ maxWidth: "10%" }}
          >
            <a href="https://atvnewsonline.com/app/">
              <img
                className="brighten-on-hover p-a-s"
                alt="homepage"
                src={DownloadIcon}
                style={{
                  height: "1.3em",
                  width: "2em",
                }}
              />
              <div className="download-button-text">App 下载</div>
            </a>
          </div>
          <div className="e-paper-bottom-navigation-parent">
            <div
              className={`page-navigation-popup ${
                this.state.showPageNavigation ? "show-in" : "show-out"
              }`}
            >
              <div className="page-navigation-placement">
                <div className="page-navigation-container">
                  <div
                    className="navigation-page-scroller"
                    id="navigation-page-scroller"
                  >
                    {this.state.thumbnails.map((page, index) => (
                      <div
                        key={"epaper_image-" + index}
                        ref={index}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 16,
                          justifyContent: "space-evenly",
                        }}
                      >
                        <img
                          loading="lazy"
                          alt="atv"
                          onClick={() => {
                            this.selectPage(index + 1);
                            this.zoomImage(1);
                          }}
                          className={`epaper_image ${
                            index === this.state.currentPage - 1
                              ? "current-epaper"
                              : ""
                          }`}
                          src={page}
                        />
                        <div id="test" className="page_navigation_number">
                          {index + 1}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="navigation-page-text">
              第{this.state.currentPage}/{this.state.images.length}页
            </div>
            <div className={`e-paper-bottom-navigation`}>
              <img
                className="clickable-button e-paper-bottom-navigation-button"
                src={FirstPageIcon}
                style={{
                  opacity: this.state.currentPage > 1 ? 1 : 0.25,
                }}
                alt="first page"
                onClick={() => {
                  if (this.state.ableFirstPage) {
                    this.playFlipSound();
                    $(this.ePaper).turn("page", 1);
                  }
                }}
              />
              <img
                className="clickable-button e-paper-bottom-navigation-button"
                src={PrevPageIcon}
                style={{
                  opacity: this.state.currentPage > 1 ? 1 : 0.25,
                }}
                alt="prev page"
                onClick={() => {
                  this.playFlipSound();
                  $(this.ePaper).turn("previous");
                }}
              />
              <img
                className="clickable-button e-paper-bottom-navigation-button"
                src={ZoomOutIcon}
                style={{
                  opacity: this.state.ableZoomOut ? 1 : 0.25,
                }}
                alt="zoom out"
                onClick={() => {
                  this.zoomImage(this.state.zoomState - this.zoomPower);
                }}
              />
              <img
                className="clickable-button e-paper-bottom-navigation-button"
                src={AllPagesIcon}
                style={{
                  padding: "0.25em",
                }}
                alt="all-pages"
                onClick={() => {
                  let show = !this.showPageNavigation;
                  this.setState(
                    {
                      showPageNavigation: !this.state.showPageNavigation,
                    },
                    () => {
                      if (show) {
                        let navigationPageScroller =
                          window.document.getElementById(
                            "navigation-page-scroller"
                          );
                        if (
                          navigationPageScroller !== undefined &&
                          navigationPageScroller !== null
                        ) {
                          navigationPageScroller.scrollTo(
                            (navigationPageScroller.offsetWidth / 10) *
                              this.state.currentPage -
                              navigationPageScroller.offsetWidth / 10,
                            0
                          );
                        }
                      }
                    }
                  );
                }}
              />
              <img
                className="clickable-button e-paper-bottom-navigation-button"
                src={ZoomInIcon}
                style={{
                  opacity: this.state.ableZoomIn ? 1 : 0.25,
                }}
                alt="zoom in"
                onClick={() => {
                  this.zoomImage(this.state.zoomState + this.zoomPower);
                }}
              />
              <img
                className="clickable-button e-paper-bottom-navigation-button"
                src={NextPageIcon}
                style={{
                  opacity:
                    this.state.currentPage < this.state.images.length - 1
                      ? 1
                      : 0.25,
                }}
                alt="next page"
                onClick={() => {
                  this.playFlipSound();
                  $(this.ePaper).turn("next");
                }}
              />
              <img
                className="clickable-button e-paper-bottom-navigation-button"
                src={LastPageIcon}
                style={{
                  opacity:
                    this.state.currentPage < this.state.images.length - 1
                      ? 1
                      : 0.25,
                }}
                alt="last page"
                onClick={() => {
                  if (this.state.ableLastPage) {
                    this.playFlipSound();
                    $(this.ePaper).turn("page", this.state.images.length);
                  }
                }}
              />
            </div>
          </div>
          <div
            className={`absolute center full-size video-parent ${
              this.state.showVideo ? "fade-in" : "fade-out"
            }`}
          >
            <div className="absolute center full-size">
              {this.state.showVideo ? (
                <video
                  id={this.state.videoURL}
                  style={{
                    width: "-webkit-fill-available",
                    aspectRatio: "16/9",
                    margin: "2em",
                    borderRadius: "0.25em",
                    maxWidth: "720px",
                  }}
                  controls
                >
                  <source
                    src={this.state.videoURL + "#t=0.001"}
                    type="video/mp4"
                  />
                </video>
              ) : null}
            </div>
            <img
              alt=""
              className="icon-button video-close-button"
              src={CloseItemIcon}
              onClick={() => {
                this.setState({ showVideo: false });
              }}
            />
          </div>
          <div
            className={`absolute center full-size video-parent ${
              this.state.showYoutube ? "fade-in" : "fade-out"
            }`}
          >
            <div className="center full-size" style={{}}>
              {this.state.showYoutube ? (
                <iframe
                  style={{
                    width: "-webkit-fill-available",
                    aspectRatio: "16/9",
                    padding: "2em",
                    maxWidth: "720px",
                  }}
                  src={`https://www.youtube.com/embed/${this.state.youtubeURL}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : null}
            </div>
            <img
              alt=""
              className="icon-button video-close-button"
              src={CloseItemIcon}
              onClick={() => {
                this.setState({ showYoutube: false });
              }}
            />
          </div>
          {this.state.shareOptions ? (
            <div className="share-button-page-selection-dropdown">
              <RWebShare
                data={{
                  text: `电子报 - ${this.state.title}`,
                  url: this.getEntirePage(),
                  title: `电子报 - ${this.state.title}`,
                }}
                onClick={() =>
                  this.setState(
                    { shareOptions: !this.state.shareOptions },
                    console.log("已成功分享")
                  )
                }
              >
                <div className="share-button-page-selection-text">
                  分享此电子报
                </div>
              </RWebShare>

              <hr
                style={{
                  border: " 1px solid rgba(255, 67, 73, 0.5)",
                  width: "100%",
                }}
              ></hr>

              <RWebShare
                data={{
                  text: `电子报 - ${this.state.title}`,
                  url: this.getCurrentPage(this.state.currentPage),
                  title: `电子报 - ${this.state.title}`,
                }}
                onClick={() =>
                  this.setState(
                    { shareOptions: !this.state.shareOptions },
                    console.log("已成功分享")
                  )
                }
              >
                <div className="share-button-page-selection-text">
                  分享第{this.state.currentPage}页
                </div>
              </RWebShare>

              {this.state.currentPage !== 1 &&
              this.state.currentPage !== this.state.images.length &&
              window.innerWidth >= window.innerHeight ? (
                <div>
                  <hr
                    style={{
                      border: " 1px solid rgba(255, 67, 73, 0.5)",
                      width: "100%",
                    }}
                  ></hr>
                  <RWebShare
                    data={{
                      text: `电子报 - ${this.state.title}`,
                      url: this.getCurrentPage(this.state.currentPage + 1),
                      title: `电子报 - ${this.state.title}`,
                    }}
                    onClick={() =>
                      this.setState(
                        { shareOptions: !this.state.shareOptions },
                        console.log("已成功分享")
                      )
                    }
                  >
                    <div className="share-button-page-selection-text">
                      分享第{this.state.currentPage + 1}页
                    </div>
                  </RWebShare>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </ReactDocumentTitle>
    );
  }
}

export default EPaperPage;
