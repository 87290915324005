import APIHeader from "../configs/APIHeader";
import APIPaths from "../configs/APIPaths";
import APIResult from "../configs/APIResult";
import { debugPrint } from "./Utilities";
import { createBrowserHistory } from "history";

async function callAPI(body, target, defaultReturn) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": APIHeader.contentType,
      apikey: APIHeader.apiKey,
    },
    body: JSON.stringify(body),
  };

  debugPrint(target);
  debugPrint(body);

  var proceed = true;

  Object.values(body).forEach((param) => {
    if (param === null || param === undefined) {
      proceed = false;
    }
  });

  if (proceed) {
    await new Promise((resolve) => setTimeout(resolve, 500));
    return await fetch(target, requestOptions)
      .then((response) => response.json())
      .then((responseData) => {
        debugPrint(responseData);
        try {
          if (responseData.status === APIResult.INVALID_SESSION) {
            createBrowserHistory().push("/login?to=1");
            window.location.reload();
          } else if (responseData.status === APIResult.INVALID) {
            createBrowserHistory().push("/login?to=5");
            window.location.reload();
          }
        } catch (e) {
          debugPrint(e);
        }
        return responseData;
      })
      .catch((error) => {
        console.warn(error);
        return defaultReturn;
      });
  } else {
    return defaultReturn;
  }
}

export async function getEPaperDetails(ePaperID) {
  var body = {
    epaper_id: ePaperID,
  };
  var target = APIPaths.apiEndPoint.concat(APIPaths.ePaperGetDetails);

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "-",
    t: [],
  });
}

export async function getEPaperList(
  startDate,
  endDate,
  page,
  itemPerPage,
  titleKeyword,
  hashtagKeyword
) {
  var body = {
    start_date: startDate,
    end_date: endDate,
    page: page,
    item_per_page: itemPerPage,
    title_keyword: titleKeyword,
    hashtag_keyword: hashtagKeyword,
  };
  var target = APIPaths.apiEndPoint.concat(APIPaths.ePaperGetList);

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "-",
    t: [],
  });
}

export async function getEPaperRecommendation(hashtags) {
  var body = {
    hashtags: hashtags,
  };
  var target = APIPaths.apiEndPoint.concat(APIPaths.ePaperGetRecommendation);

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "-",
    t: [],
  });
}

export async function addReadCount(ePaperID) {
  var body = {
    epaper_id: ePaperID,
  };
  var target = APIPaths.apiEndPoint.concat(APIPaths.increaseReadCount);

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "-",
    t: [],
  });
}

export async function getLegacyEPaper() {
  var body = {};
  var target = "https://epaper.atvnewsonline.com/asset/getpaper/cache.php";

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "-",
    t: [],
  });
}
